import Link from "next/link";

const Footer = () => {
  return (
    <footer className='p-3 text-white'>
      <p className="mt-3 text-center md:float-left">A side project by <a href="https://conorhaining.com" className="text-orange-500 underline">Conor Haining</a></p>
      <p className="mt-3 text-center md:float-right">Send me some <Link href="/feedback" replace><span className="text-orange-500 underline">feedback</span></Link></p>
    </footer>
  )
}

export default Footer;
import 'mapbox-gl/dist/mapbox-gl.css';
import '../styles/globals.css';

import type { AppProps } from 'next/app'
import Head from 'next/head';
import PlausibleProvider from 'next-plausible';
import dynamic from 'next/dynamic';
import Header from '../components/Header';
import Footer from '../components/Footer';

function MyApp({ Component, pageProps }: AppProps) {

  // const iOsPopup = dynamic(() => import('../components/iOSPopup'), {
  //  ssr: false
  // });

  return (
    <>
      <Head>
        <link rel="manifest" href="manifest.webmanifest" />

        <title>Park Your Bike - Find Bike Parking Near Your Destination</title>
        <meta name="description" content="A full end to end journey planner where you can find a place to securely park and lock your bike." />

        <meta property="og:url" content="https://parkur.bike/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Park Your Bike - Find Bike Parking Near Your Destination" />
        <meta property="og:description" content="A full end to end journey planner where you can find a place to securely park and lock your bike." />
        <meta property="og:image" content="https://parkur.bike/header.png" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="parkur.bike" />
        <meta property="twitter:url" content="https://parkur.bike/" />
        <meta name="twitter:title" content="Park Your Bike - Find Bike Parking Near Your Destination" />
        <meta name="twitter:description" content="A full end to end journey planner where you can find a place to securely park and lock your bike." />
        <meta name="twitter:image" content="https://parkur.bike/header.png" />
        <meta name="twitter:creator" content="@conorhaining" />

      </Head>


      <PlausibleProvider domain="parkur.bike">
        <div className='container mx-auto max-w-3xl'>
          <Header />
          <Component className {...pageProps} />
          <Footer />
        </div>
      </PlausibleProvider>

      {/* <iOsPopup /> */}
    </>
  )
}

export default MyApp

import Link from "next/link";
import { useRouter } from "next/router";

const Header = () => {
  const router = useRouter();

  return (
    <header className='flex justify-between p-3 text-white cursor-pointer'>
      <Link href="/">
        <div className="flex items-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="70" height="40" viewBox="0 0 70 40">
            <path d="M0 39h70" fill="none" stroke="#f17f41" strokeWidth="3" />
            <path d="M16 39v-6s0-5 5-5c4 0 4 5 4 5v6" fill="none" stroke="#f17f41" strokeWidth="1.8" />
            <g fill="none" stroke="#f17f41" transform="matrix(1.82032 0 0 1.82032 -4 -19)">
              <circle cx="16.9" cy="25.9" r="3.6" />
              <circle cx="31" cy="25.9" r="3.6" />
              <path d="M31 26h-6l-4-5h6z" strokeWidth=".8" strokeLinejoin="round" />
              <path d="M19 18h3l-5 8" strokeWidth=".8" strokeLinecap="round" />
              <path d="m25 26 2-5 1-3h2-3" strokeWidth=".8" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <text xmlSpace="preserve" x="1.4" y="23.2" fontWeight="400" fontSize="5.3" fontFamily="sans-serif" fill="#f17f41" strokeWidth=".1" transform="matrix(1.82032 0 0 1.82032 6 -31)"><tspan x="1.4" y="23.2" fontWeight="700">P</tspan></text><path fill="none" stroke="#f17f41" strokeWidth="1.456256" strokeLinecap="round" strokeLinejoin="round" d="M6 3h11v9H6z" />
            <path d="M11 12v26" fill="none" stroke="#f17f41" strokeWidth="1.82032" />
          </svg>
          <h1 className='text-xl'>Park Ur Bike</h1>
        </div>
      </Link>
      {router.asPath != "/" && <Link href="/">
      <div className="bg-white py-3 px-4 block border-gray-200 rounded-md text-sm dark:bg-gray-800 dark:border-gray-700 text-gray-500 flex content-center">
        <svg className="fill-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="24px" height="24px"><path d="M 24 2.8886719 C 12.365714 2.8886719 2.8886719 12.365723 2.8886719 24 C 2.8886719 35.634277 12.365714 45.111328 24 45.111328 C 29.036552 45.111328 33.664698 43.331333 37.298828 40.373047 L 52.130859 58.953125 C 52.130859 58.953125 55.379484 59.435984 57.396484 57.333984 C 59.427484 55.215984 58.951172 52.134766 58.951172 52.134766 L 40.373047 37.298828 C 43.331332 33.664697 45.111328 29.036548 45.111328 24 C 45.111328 12.365723 35.634286 2.8886719 24 2.8886719 z M 24 7.1113281 C 33.352549 7.1113281 40.888672 14.647457 40.888672 24 C 40.888672 33.352543 33.352549 40.888672 24 40.888672 C 14.647451 40.888672 7.1113281 33.352543 7.1113281 24 C 7.1113281 14.647457 14.647451 7.1113281 24 7.1113281 z" /></svg>
        <div className="ml-3">Search Again</div></div></Link>}
    </header>
  )
}

export default Header;